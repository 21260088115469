/* CalendarAvailability.css */

/* Wrap your entire Calendar in a custom class */
.dnd-dark-calendar {
    /* A nice background color for the whole wrapper */
    /*background-color: #2a3b4c;  a dark blue-ish color */
  
    /* Some padding or margin if you like */
    padding: 10px;
    border-radius: 8px;
  }
  
  /* The toolbar (top row with Next, Back, Today) */
  .dnd-dark-calendar .rbc-toolbar {
    background-color: #3d5268;
    color: #f0f0f0;
    padding: 8px;
    border-radius: 4px;
  }
  
  /* The Title (e.g. "December 15 - 21") in the toolbar */
  .dnd-dark-calendar .rbc-toolbar-label {
    font-size: 1.25rem;
    font-weight: 600;
  }
  
  /* The day headers (Sun, Mon, etc.) */
  .dnd-dark-calendar .rbc-header {
    background-color: #3d5268;
    color: white;
    font-weight: bold;
    border-right: 1px solid #2a3b4c; /* or some other color */
  }
  
  /* Remove default border under the header row, if desired */
  .dnd-dark-calendar .rbc-header + .rbc-header {
    /* borderline example, might need to adjust */
    border-left: 1px solid #2a3b4c;
  }
  
  /* The actual time grid cells */
  .dnd-dark-calendar .rbc-day-bg {
    background-color: #2a3b4c;
    border: 1px solid #3d5268;
  }
  
  .dnd-dark-calendar .rbc-time-view .rbc-time-gutter {
    background-color: #2a3b4c;
    color: #f0f0f0;
  }
  
  /* The “now” day highlight */
  .dnd-dark-calendar .rbc-today {
    background-color: #31475d !important;
  }
  
  /* The event boxes (where your "DM Slot" shows) */
  .dnd-dark-calendar .rbc-event {
    background-color: #2684ff; /* or your accent color */
    color: white;
    border: 1px solid #195fab;
    border-radius: 4px;
    padding: 2px 4px;
    font-size: 0.85rem;
  }
  
  /* On event hover or focus, optionally add a subtle glow */
  .dnd-dark-calendar .rbc-event:hover {
    background-color: #7a1f1f;
  }
  
  /* The agenda view lines, etc. */
  .dnd-dark-calendar .rbc-agenda-view table {
    background-color: #2a3b4c;
    color: #f0f0f0;
  }
  
  /* Force each time-slot row to be smaller. */
.dnd-dark-calendar .rbc-time-slot {
  border:none;
  /* adjust as needed */
}

.dnd-dark-calendar .rbc-timeslot-group{
  border:none;
  /* adjust as needed */
}

.dnd-dark-calendar .rbc-time-view {
  border: 1px solid rgba(255, 255, 255, 0.12);
  /* adjust as needed */
}

.dnd-dark-calendar .rbc-toolbar button {
  background-color: #3d5268;
  color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
 
  margin: 0 4px;
}

.dnd-dark-calendar .rbc-today {
  background-color: #6d8eaf;
}

.dnd-dark-calendar .rbc-event {
  background-color: #811915;
  color: #f0f0f0;
  border: 1px solid #6d8eaf;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 0.85rem;
}
/* Base class for DM slots */
.dnd-dark-calendar .dm-slot {
  background-color: #8279aa; 
  color: #fff;
}

/* Booked */
.dm-slot.dm-slot-booked {
  background-color: #3f2c2c; 
}
.dm-slot.dm-slot-booked:hover {
  background-color: #8a7070; 
}

/* Pending requests */
.dm-slot.dm-slot-pending {
  /* Maybe add a border or a new background */
  border: 2px dashed #43396e; 
  background-color: #43396e;
}


/* Wrap your entire Calendar in a custom class */
.dnd-dark-calendar {
  padding: 10px;
  border-radius: 8px;
}

/* Toolbar (top row with Next, Back, Today) */
.dnd-dark-calendar .rbc-toolbar {
  background-color: #3d5268;
  color: #f0f0f0;
  padding: 8px;
  border-radius: 4px;
}
.dnd-dark-calendar .rbc-toolbar-label {
  font-size: 1.25rem;
  font-weight: 600;
}
.dnd-dark-calendar .rbc-toolbar button {
  background-color: #3d5268;
  color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 4px;
}

/* Day headers (Sun, Mon, etc.) */
.dnd-dark-calendar .rbc-header {
  background-color: #3d5268;
  color: #ffffff;
  font-weight: bold;
  border-right: 1px solid #2a3b4c;
}
.dnd-dark-calendar .rbc-header + .rbc-header {
  border-left: 1px solid #2a3b4c;
}

/* Time grid cells */
.dnd-dark-calendar .rbc-day-bg {
  background-color: #2a3b4c;
  border: 1px solid #3d5268;
}

/* The gutter on the left that shows times */
.dnd-dark-calendar .rbc-time-view .rbc-time-gutter {
  background-color: #2a3b4c;
  color: #f0f0f0;
}

/* 'Now' day highlight */
.dnd-dark-calendar .rbc-day-bg.rbc-today {
  background-color: #31475d !important;
}

/* Base styles for all .rbc-event boxes */
.dnd-dark-calendar .rbc-event {
  background-color: #2684ff; /* or your primary accent color #80c683 */
  color: #ffffff;
  border: 1px solid #195fab;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 0.85rem;
}
.dnd-dark-calendar .rbc-event:hover {
  background-color: #1f4b7a;
}

/* Agenda view table styling */
.dnd-dark-calendar .rbc-agenda-view table {
  background-color: #2a3b4c;
  color: #f0f0f0;
}

/* Shrink the vertical spacing in each timeslot if desired */
.dnd-dark-calendar .rbc-time-slot,
.dnd-dark-calendar .rbc-timeslot-group {
  border: none;
}
.dnd-dark-calendar .rbc-time-view {
  border: 1px solid rgba(255, 255, 255, 0.12);
}

/* 
 * DM-specific slot classes
 * These get assigned in your events array 
 * with e.g. className = 'dm-slot dm-slot-pending' 
 */

/* Base class for DM slot: unbooked, no requests */
.dnd-dark-calendar .dm-slot {
  background-color: #435a6f;  /* "available" style */
  color: #fff;
}

/* Booked slot style */
.dnd-dark-calendar .dm-slot.dm-slot-booked {
  background-color: #5f443c;
}

/* Pending requests style */
.dnd-dark-calendar .dm-slot.dm-slot-pending {
  background-color: #605684;
  border: 2px solid #605684;
  /* optionally a dashed or different border style if you prefer:
   * border-style: dashed;
   */
}
